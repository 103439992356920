var Slovnicek: React.StatelessComponent<{}> = (props: {}, ctx: sitemapRouter.IContext) => <div>
  <Page>
    Výzva<br/>
    Personální podpora<br/>
    DVPP<br/>
    Inkluze<br/>
    Šablona<br/>
    Společné vzdělávání<br/>
    Aktivita<br/>
    Projekt<br/>
  </Page>
</div>;

Slovnicek.contextTypes = sitemapRouter.childContextTypes;

